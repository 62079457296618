import styled from "styled-components";

interface Props {
  isFooterVisibleInMobile?: boolean;
  hasUnderline?: boolean;
}

const AccountContactUsStyled = styled.div<Props>`
  background: ${(props) => props.theme.color.primary.coleslawWhite};
  text-align: center;
  padding: 60px 0 0;
  > div:first-child {
    height: 77.49px;
    width: 77.49px;
  }
  @media only screen and (max-width: ${(props) => props.theme.size.sm}) {
    display: ${(props) => (props.isFooterVisibleInMobile ? "block" : "none")};
    padding: 50px 0 12px;
  }

  .linkConatct {
    margin-top: 11px;
    .secondParagraph {
      margin-top: 25px;
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    background: ${(props) => props.theme.color.primary.coleslawWhite};
    padding-top: 32px;
    padding-bottom: 32px;
    .textStyles {
      font-size: 12px;
    }
    .linkStyles {
      font-size: 12px;
      line-height: 0;
    }
    .secondParagraph {
      margin-top: 20px !important;
    }
  }
`;

export default AccountContactUsStyled;
