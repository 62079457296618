import styled from "styled-components";
import { PATTERN_REWARD_CHALLENGE } from "assets/images";

export const ChallengeCalloutStyled = styled.div<{ onlyInAppTextLength: number }>`
  min-height: 295px;
  width: 100%;
  background: url(${PATTERN_REWARD_CHALLENGE.src});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 45px;
  margin-top: 12px;
  .challengeDetails {
    width: 565px;
    height: 100%;
    .challengeHeading {
      align-items: center;
      text-align: center;
      .firstTitle,
      .secondTitle {
        display: inline;
      }
      .firstTitle {
        font-family: ${(props) => props.theme.font?.barlowCondensed};
      }
    }
    .challengeDescription {
      text-align: center;
      text-transform: uppercase;
    }
    .horizontalLine {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 9px;
      span {
        height: 3px;
        width: 32px;
        background: ${(props) => props.theme.color?.primary.pelletIceBlue};
        border-radius: 5px;
        ${({ onlyInAppTextLength }) => onlyInAppTextLength > 41 && `display: none;`}
        /* stylelint-disable no-descending-specificity */
        &:first-child {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
        &:last-child {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
    }
    .challengeBody {
      padding-top: 16px;
      text-align: center;
      line-height: normal;
      font-family: ${(props) => props.theme.font?.barlow};
    }
  }
  .challengeImage {
    position: relative;
    top: 17px;
  }
  ${(props) => props.theme.responsive?.max.lg} {
    flex-direction: column;
    padding-top: 60px;
    gap: 20px;
    .challengeDetails {
      width: 100%;
    }
    .challengeBody {
      padding: 0 8px;
      padding-top: 24px;
      width: 230px;
      margin: auto;
    }
    .horizontalLine {
      span {
        width: 25px;
        ${({ onlyInAppTextLength }) => onlyInAppTextLength > 31 && `display: none;`}
      }
    }
    .challengeImage {
      top: 3px;
    }
  }
`;

export const ChallengeCalloutAPPLogo = styled.div`
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.color?.primary.coleslawWhite};
  box-shadow: 0px -2px 3px 0px ${(props) => props.theme.color?.gray.mobileBoxShadow};
  width: 100%;
  .download-apps-header {
    display: flex;
    padding-bottom: 8px;
  }
`;
