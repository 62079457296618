import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/types/types";
import NextSlideArrow, { ImageVariantProps } from "./NextSlideArrow";
import PrevSlideArrow from "./PrevSlideArrow";
import { SliderWrapper } from "./SliderStyled";

interface SliderProps {
  children: React.ReactNode;
  direction?: "ltr" | "rtl";
  slideWidth: number;
  slideMargin: number;
  scrollToCategoryIndex?: number;
  scrollToItemIndex?: number;
  device?: string;
  activeSpotlightItemIndex?: number;
  cardType?: "ZAX_CARD" | "RECENT_ORDER" | string;
  className?: string;
  isItemLeftAlligned?: boolean;
  stylePartialSlides?: boolean;
  stylePartialSlidesMobile?: boolean;
  imageArrowVariant?: ImageVariantProps;
  onAllItemsFitInViewChange?: (allItemsFitInView: boolean) => void;
}

const SCROLL_THRESHOLD = 40; // Pixels; adjust as needed

const GHOST_CARD_COUNT = 2; // Added these card at the beginning & end to fix the flickering issues

const Slider: React.FC<SliderProps> = ({
  children,
  direction,
  slideWidth,
  slideMargin,
  scrollToCategoryIndex,
  scrollToItemIndex,
  activeSpotlightItemIndex,
  device,
  cardType = "ZAX_CARD",
  className = "",
  isItemLeftAlligned,
  stylePartialSlides = false,
  stylePartialSlidesMobile = false,
  imageArrowVariant,
  onAllItemsFitInViewChange,
}) => {
  const { device: currentDevice } = useSelector((state: RootState) => ({
    device: state.currentDevice.device,
  }));
  const sliderRef = useRef<HTMLDivElement>(null);
  const prevArrowRef = useRef<HTMLButtonElement>(null);
  const nextArrowRef = useRef<HTMLButtonElement>(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [allItemsFitInView, setAllItemsFitInView] = useState(false);
  const [partialSlides, setPartialSlides] = useState(new Set<number>());

  const isRtl = direction === "rtl";

  useEffect(() => {
    if (activeSpotlightItemIndex !== undefined && activeSpotlightItemIndex !== null && activeSpotlightItemIndex >= 0) {
      scrollToIndex(activeSpotlightItemIndex);
    }
  }, [activeSpotlightItemIndex]);

  useEffect(() => {
    checkIfAllItemsFitInView();
    window.addEventListener("resize", checkIfAllItemsFitInView);

    return () => window.removeEventListener("resize", checkIfAllItemsFitInView);
  }, [children, slideWidth, slideMargin, device]);

  useEffect(() => {
    identifyPartialSlides();
    // add event listener on scroll to identify partial slides
    sliderRef.current?.addEventListener("scroll", identifyPartialSlides);

    return () => sliderRef.current?.removeEventListener("scroll", identifyPartialSlides);
  }, [children, slideWidth, slideMargin, device]);

  const checkScrollPosition = () => {
    if (sliderRef?.current) {
      const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;
      setIsAtStart(isRtl ? scrollLeft >= -SCROLL_THRESHOLD : scrollLeft <= SCROLL_THRESHOLD);
      setIsAtEnd(
        isRtl
          ? Math.abs(scrollLeft) >= scrollWidth - clientWidth - SCROLL_THRESHOLD
          : scrollLeft + clientWidth >= scrollWidth - SCROLL_THRESHOLD
      );
    }
  };

  const identifyPartialSlides = () => {
    const newPartialSlides = new Set<number>();
    if (sliderRef.current) {
      const slider = sliderRef.current;
      const childrenArray = Array.from(slider.children) as HTMLElement[];
      const prevArrowRect = prevArrowRef.current?.getBoundingClientRect();
      const nextArrowRect = nextArrowRef.current?.getBoundingClientRect();

      childrenArray.forEach((child, index) => {
        // Now child is treated as an HTMLElement, which has offsetLeft and offsetWidth
        const offsetLeft = child.offsetLeft;
        const offsetWidth = child.offsetWidth;
        const scrollLeft = slider.scrollLeft;
        const clientWidth = slider.clientWidth;
        const slideRect = child.getBoundingClientRect();

        // check to determine if the slide is intersecting with prev arrow
        if (prevArrowRect && prevArrowRect.right >= slideRect.left && prevArrowRect.left <= slideRect.right) {
          newPartialSlides.add(index);
        }

        // check to determine if the slide is intersecting with next arrow
        if (
          nextArrowRect &&
          nextArrowRect.right >= slideRect.left &&
          // the right position is reduced by half of the width of the slide
          // to only make a slide partial if no other is visible on the right side
          nextArrowRect.left <= slideRect.right - slideRect.width / 2
        ) {
          newPartialSlides.add(index);
        }

        // check to determine if the slide is partial
        if (offsetLeft < scrollLeft || offsetLeft + offsetWidth > scrollLeft + clientWidth) {
          newPartialSlides.add(index);
        }
      });
    }
    setPartialSlides(newPartialSlides);
  };

  const nextSlide = () => {
    const slider = sliderRef.current;
    if (slider) {
      const totalSlideWidth = slideWidth + slideMargin * 2;
      const slideUsage = slider.clientWidth / totalSlideWidth;
      const integerPart = Math.floor(slideUsage);
      const fractionalPart = slideUsage - integerPart;
      const partialSlideWidth = totalSlideWidth * fractionalPart;
      const nextSlideScrollAmount = slider.clientWidth - partialSlideWidth;

      if (isRtl) {
        // Adjust scrollLeft for RTL
        slider.scrollTo({ left: slider.scrollLeft - nextSlideScrollAmount, behavior: "smooth" });
      } else {
        slider.scrollTo({ left: slider.scrollLeft + nextSlideScrollAmount, behavior: "smooth" });
      }

      checkScrollPosition();
    }
  };

  const prevSlide = () => {
    const slider = sliderRef.current;
    if (slider) {
      const totalSlideWidth = slideWidth + slideMargin * 2;
      const slideUsage = slider.clientWidth / totalSlideWidth;
      const fullyVisibleSlides = Math.floor(slideUsage);
      const fractionalPart = slideUsage - fullyVisibleSlides;

      let prevSlideScrollAmount;
      if (fractionalPart > 0) {
        // If there's a partial slide, account for it in the scroll amount
        prevSlideScrollAmount = fullyVisibleSlides * totalSlideWidth - totalSlideWidth * fractionalPart;
      } else {
        // If there's no partial slide, scroll back by the width of the fully visible slides minus one
        prevSlideScrollAmount = (fullyVisibleSlides - 1) * totalSlideWidth;
      }

      if (isRtl) {
        // Adjust scrollLeft for RTL
        slider.scrollTo({ left: slider.scrollLeft + prevSlideScrollAmount, behavior: "smooth" });
      } else {
        slider.scrollTo({ left: slider.scrollLeft - prevSlideScrollAmount, behavior: "smooth" });
      }

      checkScrollPosition();
    }
  };

  const scrollToIndex = (index: number) => {
    const slider = sliderRef.current;
    if (slider) {
      // Adjust the index to account for the ghost card at the start
      const adjustedIndex = index + 1;
      const slide = slider.children[adjustedIndex] as HTMLElement;
      if (slide) {
        const scrollPosition = slide.offsetLeft - slider.offsetWidth / 2 + slide.offsetWidth / 2;
        slider.scrollTo?.({ left: scrollPosition, behavior: "smooth" });
      }
      checkScrollPosition(); // Update button visibility
    }
  };

  const checkIfAllItemsFitInView = () => {
    if (sliderRef.current) {
      const totalWidthOfItems = (React.Children.count(children) - GHOST_CARD_COUNT) * (slideWidth + slideMargin);
      const allItemsFit = sliderRef.current.clientWidth >= totalWidthOfItems;
      setAllItemsFitInView(allItemsFit);
      if (onAllItemsFitInViewChange) {
        onAllItemsFitInViewChange(allItemsFit);
      }
    }
  };

  useEffect(() => {
    // Handle scrolling to specific category and item indices
    if (scrollToCategoryIndex !== undefined && scrollToItemIndex !== undefined) {
      scrollToIndex(scrollToCategoryIndex * React.Children.count(children) + scrollToItemIndex);
    }
  }, [scrollToCategoryIndex, scrollToItemIndex, children]);

  const getMarginRightBasedOnIndex = (index: number) => {
    switch (index) {
      case 0:
        return slideMargin + 10;
      case React.Children.count(children) - 2: // -2 because at the end one card is added to fix the flickering issue
        return 0;
      default:
        return slideMargin;
    }
  };

  return (
    <SliderWrapper
      isItemLeftAlligned={isItemLeftAlligned}
      device={device ?? currentDevice}
      stylePartialSlides={stylePartialSlides}
      stylePartialSlidesMobile={stylePartialSlidesMobile}
      className={`slider-container ${isRtl ? "rtl" : ""}`}
    >
      {!isAtStart && !allItemsFitInView && (
        <PrevSlideArrow ref={prevArrowRef} onClick={prevSlide} type={cardType} imageVariant={imageArrowVariant} />
      )}
      <div
        className={`slider-wrapper ${allItemsFitInView && cardType === "ZAX_CARD" ? "center-items" : "start-items"}`}
        ref={sliderRef}
        onScroll={checkScrollPosition}
      >
        {React.Children.map(children, (child, index) => (
          <div
            key={index}
            style={{
              flex: "0 0 auto",
              width: `${slideWidth}px`,
              marginRight: `${getMarginRightBasedOnIndex(index)}px`,
              paddingLeft: `${index === 0 ? "10" : "0"}px`,
            }}
            className={`slide ${className} ${partialSlides.has(index) ? "partial-slide" : ""}`}
          >
            {child}
          </div>
        ))}
      </div>
      {!isAtEnd && !allItemsFitInView && (
        <NextSlideArrow ref={nextArrowRef} onClick={nextSlide} type={cardType} imageVariant={imageArrowVariant} />
      )}
    </SliderWrapper>
  );
};

export default Slider;
