import Image from "next/image";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import SubHeadings from "components/atomic-components/atoms/typography/SubHeadings/SubHeadings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import { ANALYTICS_CONST } from "constants/analytics";
import Analytics from "analytics/Analytics";
import Button from "components/atomic-components/atoms/button/Button";
import { JoinRewardCardStyled } from "./JoinRewardCardStyled";

interface Props {
  title: string | null;
  description: string | null;
  expiryDate?: string | null;
  buttonCTA: string | null;
  imageURL?: string | null;
  onTermsClick: () => void;
  onBtnClick: () => void;
}

const JoinRewardCard = ({ imageURL, title, description, expiryDate, onTermsClick, onBtnClick, buttonCTA }: Props) => {
  const onRewardsPopupTermsClick = () => {
    Analytics.getInstance().logEvent(
      ANALYTICS_CONST.REWARD.CLICK_FOR_TERMS_CONDITIONS,
      { placement: "rewards popup", rewardType: "", rewardName: title ? title.charAt(0).toUpperCase() + title.slice(1) : "" },
      false,
      true
    );
    onTermsClick();
  };
  return (
    <JoinRewardCardStyled>
      <div className="joinRewardTextView">
        <Headings variant="primaryHeading2" fontSize={58} lineHeight={60} mobileFontSize={32} mobileLineHeight={38} className="rewardTitle">
          {title}
        </Headings>
        {expiryDate && (
          <div className="horizontalLine">
            <SubHeadings variant="secondarySubHeading4" className="horizontalLineText" fontWeight={700}>
              {expiryDate}
            </SubHeadings>
          </div>
        )}
        {imageURL && (
          <div className="imageWrapper">
            <Image src={imageURL} layout="fill" alt="product" objectFit="cover" loader={() => imageURL} />
          </div>
        )}
        <Paragraph variant="primaryParagraph1" className="rewardDescription" fontSize={22} fontWeight={500}>
          {description}{" "}
          <span className="rewardLinkSpan" onClick={onRewardsPopupTermsClick}>
            VIEW TERMS
          </span>
        </Paragraph>
        <span className="rewardLink" onClick={onRewardsPopupTermsClick}>
          VIEW TERMS
        </span>
        <Button variant="primaryBlue" className="rewardButton" onClick={onBtnClick}>
          {buttonCTA ?? "JOIN TO EARN REWARDS"}
        </Button>
      </div>
      {imageURL && (
        <div className="imageWrapperDesktop">
          <Image src={imageURL} layout="fill" alt="product" objectFit="cover" objectPosition={"-90px"} loader={() => imageURL} />
        </div>
      )}
    </JoinRewardCardStyled>
  );
};

export default JoinRewardCard;
