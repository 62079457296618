import styled from "styled-components";

export const HowRewardWorkStyled = styled.div`
  width: 100%;
  .howRewardsWork {
    background: ${(props) => props.theme.color.primary.coleslawWhite};
    display: grid;
    width: 100%;
    padding: 68px 0 104px;

    .howRewardsWorkTitleContainer {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-bottom: 54px;
      .redPartOfHeader {
        color: ${(props) => props.theme.color.primary.tongueTorchRed};
        margin-left: 16px;
      }
    }

    .columns {
      display: flex;
      margin: 0 auto;
      width: 100%;
      padding: 0 15px;
      justify-content: space-around;
      align-items: baseline;

      .howRewardsWorkColumn {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 305px;

        .topText {
          margin-top: 28px;
          line-height: normal;
          color: ${(props) => props.theme.color.primary.tongueTorchRed};
        }
        .bottomText {
          text-align: center;
          text-transform: uppercase;
        }

        .description {
          width: 230px;
          margin-top: 20px;
          color: ${(props) => props.theme.color.primary.foundersBlue};
          font-family: ${(props) => props.theme.font.barlow};
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          text-align: center;
        }
      }
    }

    .download-apps-section {
      display: flex;
      flex-direction: column;
      align-items: center;

      .download-apps-header {
        margin-top: 40px;
        margin-bottom: 8px;
        h1 {
          display: inline;
        }
      }
    }

    .getStartedBtn {
      display: flex;
      justify-content: center;

      button {
        margin-top: 50px;
        width: 220px;
        height: 44px;
        color: ${(props) => props.theme.color.primary.coleslawWhite};
        background-color: ${(props) => props.theme.color.primary.tongueTorchRed};
        font-family: ${(props) => props.theme.font.barlow};
        font-size: 20px;
        font-weight: 700;
      }
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    .howRewardsWork {
      padding: 54px 0 72px;
      display: flex;
      flex-direction: column;

      .howRewardsWorkTitleContainer {
        margin-bottom: 0;
        display: inline-flex;
        align-items: baseline;
        gap: 8px;
        text-align: center;
        .howRewardsWorkTitle {
          margin-bottom: 0;
          font-family: ${(props) => props.theme.font.barlow};
        }
        .redPartOfHeader {
          font-family: ${(props) => props.theme.font.ZaxCasual};
          font-style: italic;
          margin-left: 0;
        }
      }

      .columns {
        gap: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .howRewardsWorkColumn {
          margin-top: 54px;
          .topText {
            margin-top: 28px;
          }
          .bottomText {
            margin-top: 0;
          }
          .description {
            margin-top: 20px;
          }
        }
      }

      .getStartedBtn {
        button {
          margin-top: 30px;
          width: 215px;
          font-size: 15px;
        }
      }
    }
  }
`;
