import Image from "next/image";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { apiCall } from "src/apis/api";
import { PAGE_NAME, PAGE_TYPE } from "constants/analytics";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import { IC_QR_CODE_CIRCLE, IC_QR_CODE_SQUARE_REBRAND } from "assets/images";
import CMSImage from "components/atomic-components/molecules/CMSImage/CMSImage";
import useModal from "hooks/useModal";
import { Nullable } from "types/cms/cms-types";
import { HomeCMSData } from "types/cms/home";
import { ChallengeCMSData, RewardsCMSData } from "types/cms/rewards";
import { CMS_HOME, CMS_REWARDS, CMS_WEB_GAMIFICATION } from "api/endpoints";
import { CommonLayerResponseDataWithType, RootState } from "src/types/types";
import FAQSection from "components/FAQ/FAQSection/FAQSection";
import Analytics from "analytics/Analytics";
import AccountContactUs from "components/atomic-components/molecules/AccountContactUs/AccountContactUs";
import RewardsTerms from "containers/Basket/modals/RewardsTerms/RewardsTerms";
import HowRewardWork from "components/Rewards/HowRewardWork/HowRewardWork";
import SubHeadings from "components/atomic-components/atoms/typography/SubHeadings/SubHeadings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import RewardsGuestSlider from "components/Rewards/RewardsGuestSlider/RewardsGuestSlider";
import ChallengeCallout from "components/atomic-components/molecules/ChallengeCallout/ChallengeCallout";
import JoinRewardCard from "components/atomic-components/molecules/JoinRewardCard/JoinRewardCard";
import EarnPointsHeader from "./EarnPointsHeader";
import { RewardsGuestStyled } from "./RewardsGuestStyled";

export interface TermsData {
  title: Nullable<string>;
  terms: Nullable<string>;
}

const RewardsGuest = () => {
  const { setModal } = useModal();
  const [cmsHomeData, setCmsHomeData] = useState<HomeCMSData | null>(null);
  const [cmsRewardsData, setCmsRewardsData] = useState<RewardsCMSData | null>(null);
  const [cmsChallengeData, setCmsChallengeData] = useState<ChallengeCMSData | null>(null);
  const [termsData, setTermsData] = useState<TermsData | null>(null);
  const { device, isAuthenticated } = useSelector((state: RootState) => ({
    device: state.currentDevice.device,
    isAuthenticated: state?.user?.isAuthenticated,
  }));
  const qrCodeImagePath = device === "MOBILE" ? IC_QR_CODE_SQUARE_REBRAND : IC_QR_CODE_CIRCLE;

  const fetchCmsData = async () => {
    try {
      const rewardsCmsData = (await apiCall({
        requestType: "GET",
        apiEndPoint: CMS_REWARDS,
      })) as CommonLayerResponseDataWithType<RewardsCMSData>;
      const homeCmsData = (await apiCall({
        requestType: "GET",
        apiEndPoint: CMS_HOME,
      })) as CommonLayerResponseDataWithType<HomeCMSData>;
      const challengeCmsData = (await apiCall({
        requestType: "GET",
        apiEndPoint: CMS_WEB_GAMIFICATION,
      })) as CommonLayerResponseDataWithType<ChallengeCMSData>;

      setCmsRewardsData(rewardsCmsData.response);
      setCmsHomeData(homeCmsData.response);
      setCmsChallengeData(challengeCmsData.response);
    } catch {
      setCmsRewardsData(null);
      setCmsHomeData(null);
      setCmsChallengeData(null);
    }
  };

  useEffect(() => {
    fetchCmsData();
    if (!isAuthenticated) {
      Analytics.getInstance().logEvent("page_view", {
        page_name: PAGE_NAME.REWARDS,
        page_type: PAGE_TYPE.TYPE_REWARDS,
      });
    }
  }, [isAuthenticated]);

  const appLogoDetails = cmsChallengeData?.VersionA;

  return (
    <RewardsGuestStyled>
      <div className="texturedHeader">
        {cmsHomeData?.Rewards && (
          <div className="rotatedRewardsImage">
            <Headings variant="primaryHeading1" className="exclusiveText" fontSize={51} fontWeight={700}>
              {cmsHomeData.Rewards?.Guest?.TitleLineOne}
            </Headings>
            <CMSImage
              src={cmsHomeData.Rewards?.CardImage?.url ?? ""}
              alt={cmsHomeData.Rewards?.CardImage?.alternativeText ?? "Rewards banner image"}
              width={cmsHomeData.Rewards?.CardImage?.width ?? 0}
              height={cmsHomeData.Rewards?.CardImage?.height ?? 0}
            />
            <hr className="hrRotatedRewardsImage" />
          </div>
        )}

        {cmsHomeData?.Rewards?.Guest && (
          <>
            <Headings variant="primaryHeading1" className="mobWebExclusiveText" fontWeight={700} mobileFontSize={24} mobileLineHeight={29}>
              {cmsHomeData.Rewards.Guest?.MobWebTitleLineOne}
            </Headings>
            {cmsHomeData.Rewards.Guest?.TitleLineTwo && (
              <Headings
                variant="primaryHeading2"
                fontSize={100}
                lineHeight={100}
                fontWeight={800}
                mobileFontSize={42}
                mobileLineHeight={42}
                className="rewardsTitleLineTwo"
              >
                {cmsHomeData.Rewards.Guest?.TitleLineTwo}
              </Headings>
            )}
            {cmsHomeData.Rewards.Guest?.Subtitle && (
              <Paragraph variant="primaryParagraph1" className="rewardsSubTitle">
                {cmsHomeData.Rewards.Guest?.Subtitle}
              </Paragraph>
            )}
          </>
        )}
      </div>
      {termsData && <RewardsTerms cmsRewardsTitle={termsData.title} cmsRewardsTerms={termsData.terms} onClose={() => setTermsData(null)} />}
      {cmsHomeData?.Rewards?.Guest && (
        <JoinRewardCard
          imageURL={cmsHomeData.Rewards.Guest?.FeaturedRewardImage?.url}
          title={cmsHomeData.Rewards.Guest?.FeaturedRewardTitle}
          description={cmsHomeData.Rewards.Guest?.FeaturedRewardDescription}
          expiryDate={cmsHomeData.Rewards.Guest?.FeaturedRewardExpiration}
          onTermsClick={() =>
            cmsHomeData?.Rewards?.Guest?.FeaturedRewardTitle &&
            cmsHomeData?.Rewards?.Guest?.FeaturedRewardTerms &&
            setTermsData({ title: cmsHomeData.Rewards.Guest.FeaturedRewardTitle, terms: cmsHomeData.Rewards.Guest.FeaturedRewardTerms })
          }
          buttonCTA={cmsHomeData.Rewards.Guest?.CTAText}
          onBtnClick={() => setModal("SIGNUP")}
        />
      )}
      <HowRewardWork
        isLogedIn={false}
        isChallengesEnabled={cmsChallengeData?.ChallengeSectionNonLoggedIn?.ChallengeSectionEnabDisable}
        appLogoDetails={appLogoDetails}
      />
      <div className="choiceContainer">
        <div className="rewardsChoiceSection">
          <div className="redeemRewardsPointsHeading">
            <Headings variant="primaryHeading2">REDEEM POINTS FOR</Headings>
            <Headings
              variant="primaryHeading1Red"
              mobileFontSize={24}
              mobileLineHeight={29}
              mobileFontWeight={700}
              className="accented-heading"
              fontWeight={700}
            >
              FREE FOOD!
            </Headings>
          </div>
          <SubHeadings variant="secondarySubHeading4" className="subHeading" fontWeight={500} isTextUpperCase={false}>
            Try our flavor-packed rewards.{" "}
          </SubHeadings>
          <RewardsGuestSlider items={cmsRewardsData?.TieredRewards} openTermsModal={setTermsData} />
        </div>
      </div>
      <div className="earnPointsContainer">
        <div className="titleContainer">
          <EarnPointsHeader
            device={device}
            titleMobile={cmsRewardsData?.WebCallout?.MobWebTitle}
            titleMobile2={cmsRewardsData?.WebCallout?.MobWebTitleRewards}
            titleDesktopPart1={cmsRewardsData?.WebCallout?.DesktopTitleEarnPoints}
            titleDesktopPart2={cmsRewardsData?.WebCallout?.DesktopTitleUseRewards}
          />
        </div>
        <Paragraph variant="primaryParagraph1" className="earnPointsDescription">
          {cmsRewardsData?.WebCallout?.Subtitle}
        </Paragraph>
        <div className="redeemRewardsSection">
          <div className="redeemRewardsContent">
            <Image src={qrCodeImagePath} alt="QR Code" className="qrCodeImage" />
            <Paragraph variant="paragraphHeading1" className="redeemText">
              Quick AND EASY WAY TO EARN & REDEEM REWARDS IN STORE
            </Paragraph>
          </div>
        </div>
        {cmsRewardsData?.WebCallout?.Image?.url && (
          <div className="usePointsInStore">
            <CMSImage
              src={cmsRewardsData?.WebCallout?.Image?.url}
              alt={cmsRewardsData?.WebCallout?.Image?.alternativeText || "Zax handoff image"}
              layout="fill"
              objectFit="cover"
              className="calloutImage"
              objectPosition={device === "MOBILE" ? "60%" : "center"}
            />
          </div>
        )}
      </div>
      {cmsChallengeData?.ChallengeSectionNonLoggedIn?.ChallengeSectionEnabDisable && (
        <ChallengeCallout
          {...cmsChallengeData?.ChallengeSectionNonLoggedIn}
          versionTitle={cmsChallengeData?.VersionB?.Title}
          appStoreLink={cmsChallengeData?.VersionB?.AppStoreLink}
          playStoreLink={cmsChallengeData?.VersionB?.PlayStoreLink}
        />
      )}
      {cmsRewardsData?.FAQs && (
        <FAQSection className="rewardsFaq" hasBlueBackground title={cmsRewardsData.FAQs.Title} questions={cmsRewardsData.FAQs.Questions} />
      )}
      <AccountContactUs className="contactUsFooter" hasUnderline isFooterVisibleInMobile />
    </RewardsGuestStyled>
  );
};

export default RewardsGuest;
