import React from "react";
import { useSelector } from "react-redux";
import Image from "next/image";
import { RootState } from "types/types";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import { ChallengeSection } from "types/cms/rewards";
import AppStoreLogos from "../AppStoreLogos/AppStoreLogos";
import { ChallengeCalloutStyled, ChallengeCalloutAPPLogo } from "./ChallengeCalloutStyled";

export type ChallengeCalloutType = {
  versionTitle: string;
  appStoreLink: string;
  playStoreLink: string;
};

const ChallengeCallout = ({
  Body,
  Title,
  TitleChallengeText,
  foregroundDesktopImage,
  foregroundMobileWebImage,
  OnlyInAppText,
  versionTitle,
  appStoreLink,
  playStoreLink,
}: ChallengeSection & ChallengeCalloutType) => {
  const { device } = useSelector((state: RootState) => ({
    device: state.currentDevice.device,
  }));

  const isMobile = device === "MOBILE";
  const foregroundImage = isMobile ? foregroundMobileWebImage?.url : foregroundDesktopImage?.url;
  const appLogos = playStoreLink && appStoreLink && (
    <AppStoreLogos className="footerLogos" appStoreLink={appStoreLink} playStoreLink={playStoreLink} />
  );
  const onlyInAppTextLength = OnlyInAppText?.length ?? 0;
  return (
    <>
      <ChallengeCalloutStyled onlyInAppTextLength={onlyInAppTextLength}>
        <div className="challengeDetails">
          <div className="challengeHeading">
            <Headings variant="primaryHeading2" fontSize={60} className="firstTitle" mobileFontSize={32}>
              {Title}
            </Headings>
            <Headings variant="primaryHeading1Red" mobileFontSize={28} fontSize={51} className="secondTitle">
              {TitleChallengeText}
            </Headings>
          </div>
          <Paragraph variant="secondaryParagraph4" fontSize={24} className="challengeBody" mobileFontSize={16} mobileFontWeight={500}>
            {Body}
          </Paragraph>
          {OnlyInAppText && (
            <div className="horizontalLine">
              <span></span>
              <Paragraph
                variant="secondaryParagraph2"
                fontSize={20}
                fontWeight={800}
                className="challengeDescription"
                mobileFontSize={16}
                mobileFontWeight={700}
              >
                {OnlyInAppText}
              </Paragraph>
              <span></span>
            </div>
          )}
        </div>
        <div className="challengeImage">
          <Image
            src={foregroundImage ?? ""}
            alt="foregroundImage"
            width={isMobile ? 264 : 214}
            height={isMobile ? 318 : 264}
            loader={() => foregroundImage ?? ""}
          />
        </div>
      </ChallengeCalloutStyled>
      {isMobile && (
        <ChallengeCalloutAPPLogo>
          <div className="download-apps-header">
            <Headings variant="primaryHeading3" lineHeight={18} className="secondHeader">
              {versionTitle}
            </Headings>
          </div>
          {appLogos}
        </ChallengeCalloutAPPLogo>
      )}
    </>
  );
};

export default ChallengeCallout;
