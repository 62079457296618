import styled from "styled-components";
import { IMG_TEXTURED_BKGD_REWARD_GUEST_USER } from "assets/images";

export const RewardsGuestStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .texturedHeader {
    position: relative;
    background: url(${IMG_TEXTURED_BKGD_REWARD_GUEST_USER.src}) no-repeat;
    background-size: cover;
    background-position: center bottom;
    padding: 80px 0px 60px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .exclusiveText {
    display: inline-block;
    font-style: italic;
    padding-right: 23px;
    text-transform: uppercase;
    font-family: ${(props) => props.theme.font.ZaxCasual};
    color: ${(props) => props.theme.color.primary.tongueTorchRed};
  }

  .mobWebExclusiveText,
  .hrRotatedRewardsImage {
    display: none;
  }
  .rotatedRewardsImage {
    margin-top: 0px;
  }

  .rewardsTitleLineTwo {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-transform: uppercase;
    margin-bottom: 48px;
    font-family: ${(props) => props.theme.font.barlow};
    color: ${(props) => props.theme.color.primary.coleslawWhite};
  }

  .rewardsSubTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    line-height: 28px;
    padding: 0px 155px;
    font-family: ${(props) => props.theme.font.barlowSemiCondensed};
    font-weight: 500;
    color: ${(props) => props.theme.color.core.white};
  }

  .cardContainer {
    width: min(90%, 996px);
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .freeStuff {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 58px;
      padding-left: 100px;
      padding-right: 100px;
      position: relative;

      .spiralArrowIconContainer {
        position: absolute;
        right: 0;
        margin-top: 70px;
      }

      .freeStuffText {
        text-align: left;
      }
    }
  }

  .choiceContainer {
    width: 100%;
    background: ${(props) => props.theme.color.core.white};

    .rewardsChoiceSection {
      margin: 88px auto 44px;
      text-align: center;
      .redeemRewardsPointsHeading {
        display: flex;
        justify-content: center;
        align-items: baseline;
      }
      .accented-heading {
        padding-left: 10px;
      }

      .subHeading {
        margin-top: 16px;
        margin-bottom: 64px;

        ${(props) => props.theme.responsive?.max.lg} {
          margin-bottom: 24px;
        }
      }
    }
  }

  .earnPointsContainer {
    background: ${(props) => props.theme.color.core.white};
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .titleContainer {
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      justify-content: center;
      .redPartOfHeading {
        color: ${(props) => props.theme.color.primary.tongueTorchRed};
      }
    }
    .earnPointsDescription {
      margin: 8px 0 36px 0;
      font-family: ${(props) => props.theme.font.barlowSemiCondensed};
      font-size: 24px;
      line-height: 28.8px;
      text-align: center;
      width: 56%;
      font-weight: 500;
    }

    .usePointsInStore {
      display: grid;
      place-items: center;
      position: relative;
      height: 615px;
      width: 100%;
      @media only screen and (min-width: ${(props) => props.theme.size.lg}) {
        margin-bottom: 60px;
      }
    }

    .redeemRewardsSection {
      width: 100%;
      display: flex;
      justify-content: center;

      .redeemRewardsContent {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 25px;
        padding-bottom: 40px;

        .redeemText {
          max-width: 46%;
          text-align: left;
          font-family: ${(props) => props.theme.font.barlowSemiCondensed};
          font-weight: 700;
          font-size: 28px;
          line-height: 30px;
          color: ${(props) => props.theme.color.primary.tongueTorchRed};
        }
      }
    }
  }

  .rewardsFaq {
    width: 100%;
    padding-top: 60px;
    margin-block: 0;
    .dropdownText {
      padding: 23px 28px 23px 25px;
      font-size: 16px;
      line-height: 19.2px;
      color: ${(props) => props.theme.color.secondary.darkGray};
    }

    ${(props) => props.theme.responsive.max.lg} {
      padding-top: 30px !important;
    }
  }

  .contactUsFooter {
    background: white;
    margin-bottom: 50px;
    padding-top: 0;
  }

  ${(props) => props.theme.responsive.max.lg} {
    .texturedHeader {
      padding: 60px 0px 40px 0px;
      margin-bottom: 20px;
    }

    .rotatedRewardsImage {
      margin-top: 0px;
      width: 168.04px;
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .hrRotatedRewardsImage {
      display: block;
      width: 78px;
      size: 2.64px;
      margin-top: 8px;
      color: ${(props) => props.theme.color.core.white};
    }

    .exclusiveText {
      display: none;
    }

    .mobWebExclusiveText {
      display: block;
      font-style: italic;
      text-transform: uppercase;
      padding: 0px 59px;
      font-family: ${(props) => props.theme.font.ZaxCasual};
      color: ${(props) => props.theme.color.primary.tongueTorchRed};
    }

    .rewardsTitleLineTwo {
      height: 30px;
      text-transform: uppercase;
      margin-bottom: 24px;
    }

    .rewardsSubTitle {
      font-size: 16px;
      line-height: 22px;
      color: ${(props) => props.theme.color.core.white};
      justify-content: center;
      padding: 0px 34px;
    }

    .cardContainer {
      margin-top: 0;
      margin-bottom: 40px;

      > div:first-child {
        max-height: 325px;
        height: 100%;
      }

      .cardInfo {
        .cardTitle {
          margin: 5px 0 10px;
        }

        .bottomInformation {
          margin-top: 17px;

          button,
          p {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }

      .freeStuff {
        flex-direction: column;
        padding-left: 60px;
        padding-right: 0;
        margin-top: 25px;

        > div:first-child {
          width: 100%;

          .eyelashDoodle {
            position: absolute;
            top: 0;
            left: -60px;
          }
        }

        .freeStuffText {
          font-size: 22px;
          line-height: 26px;
          margin-bottom: 25px;
        }

        .freeStuffButton {
          width: 215px;
        }

        .spiralArrowIconContainer {
          display: none;
        }
      }
    }

    .earnPointsContainer {
      padding-top: 60px;
      background: ${(props) => props.theme.color.primary.coleslawWhite};
      .mobileHeader {
        font-family: ${(props) => props.theme.font.barlowSemiCondensed};
        font-weight: 800;
        font-size: 27.65px;
        line-height: 26px;
        text-align: center;
        padding: 0 50px;
      }
      .titleContainer {
        gap: 6px;
        flex-direction: column;
        align-items: center;
      }
      .earnPointsDescription {
        font-size: 16px;
        line-height: 19.2px;
        text-align: center;
        width: 74%;
        margin: 16px 0 36px;
      }

      .usePointsInStore {
        height: 279px;

        .centerText {
          font-size: 20px;
          line-height: 15px;
          padding: 0 20px;
        }
      }

      .redeemRewardsSection {
        margin-top: 0;

        .redeemRewardsContent {
          padding: 0;
          width: 360px;
          margin-bottom: 32px;
          gap: 16px;
          .qrCodeImage {
            width: 30px !important;
            height: 29.79px !important;
          }
          .redeemText {
            max-width: 55%;
            font-size: 16px;
            line-height: 19.2px;
            text-align: center;
          }
        }
      }
    }

    .rewardsFaq {
      padding: 0;
      margin-block: 0;

      .title {
        font-size: 22px;
        line-height: 37px;
        margin-bottom: 30px;
        width: 100%;
        margin-left: 0;
        margin-top: 0;
      }

      .faqQuestion {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 30px;
        padding: 0 17px;

        ${(props) => props.theme.responsive.max.lg} {
          margin-bottom: 0;
          padding-left: 0;
        }
      }

      .dropdownText {
        padding: 16px;
        font-size: 12px;
        line-height: 14.4px;
        color: ${(props) => props.theme.color.primary.foundersBlue};
      }
    }

    .choiceContainer {
      .rewardsChoiceSection {
        width: unset;
        margin-top: 66px;
        h1 {
          font-size: 28px;
        }
        .redeemRewardsPointsHeading {
          flex-direction: column;
          align-items: center;
        }
        .accented-heading {
          padding-left: 0;
        }
        .subHeading {
          font-size: 14px;
          font-weight: 500;
          margin-top: 4px;
          margin-bottom: 16px;
          padding: 0 24px;
        }
      }
    }

    .contactUsFooter {
      margin-bottom: 30px;
      padding-top: 50px;
    }
  }
`;
