import Image from "next/image";
import { CHIKCEN_LOGO } from "assets/images";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import Link from "components/atomic-components/atoms/typography/Links/Links";
import AccountContactUsStyled from "./AccountContactUsStyled";

interface Props {
  isFooterVisibleInMobile?: boolean;
  className?: string;
  hasUnderline?: boolean;
}

const AccountContactUs = ({ isFooterVisibleInMobile, className, hasUnderline }: Props) => {
  return (
    <AccountContactUsStyled
      isFooterVisibleInMobile={isFooterVisibleInMobile}
      className={`${className} accountPageMobile`}
      hasUnderline={hasUnderline}
    >
      <Image src={CHIKCEN_LOGO} alt="Chicken RGB Full Color Image" width="65" height="65" />
      <div className="linkConatct">
        <Paragraph variant="primaryParagraph2" className="textStyles">
          You’ve got questions? We’ve got answers.
        </Paragraph>
        <Link to="/faq" className="linkStyles">
          View our FAQ
        </Link>
        <Paragraph variant="primaryParagraph2" className="secondParagraph textStyles">
          Need additional help with your account?
        </Paragraph>
        <Link to="tel:18668929297" className="linkStyles">
          CALL US
        </Link>
      </div>
    </AccountContactUsStyled>
  );
};

export default AccountContactUs;
