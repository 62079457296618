import Image from "next/image";
import React from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/types/types";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import {
  REWARD_WORK_STEP_FOUR_REBRAND,
  REWARD_WORK_STEP_ONE_REBRAND,
  REWARD_WORK_STEP_THREE_REBRAND,
  REWARD_WORK_STEP_TWO_REBRAND,
} from "assets/images";
import Button from "components/atomic-components/atoms/button/Button";
import AppStoreLogos from "components/atomic-components/molecules/AppStoreLogos/AppStoreLogos";
import { setSelectedTabName } from "redux/reducer/Menu/MenuAction";
import { VersionType } from "types/cms/rewards";
import { HowRewardWorkStyled } from "./HowRewardWorkStyled";

interface Props {
  isLogedIn?: boolean;
  isChallengesEnabled?: boolean;
  appLogoDetails?: VersionType;
}

const HowRewardWork = ({ isLogedIn, isChallengesEnabled, appLogoDetails }: Props) => {
  const { device, footerCMS } = useSelector((state: RootState) => ({
    device: state.currentDevice.device,
    footerCMS: state.cms?.footer,
  }));

  const router = useRouter();
  const dispatch = useDispatch();

  const navigateToMenuPage = () => {
    dispatch(setSelectedTabName("Menu"));
    router.push("/menu");
  };

  const isDesktop = device === "DESKTOP";
  const headingVariantFirstWord = isDesktop ? "primaryHeading2" : "primaryHeading1";
  const headingVariantSecondWord = isDesktop ? "primaryHeading1" : "primaryHeading2";

  const appStore = appLogoDetails?.AppStoreLink ?? footerCMS?.AppStoreLink;
  const playStore = appLogoDetails?.PlayStoreLink ?? footerCMS?.PlayStoreLink;

  const appLogos = <AppStoreLogos className="footerLogos" appStoreLink={appStore ?? ""} playStoreLink={playStore ?? ""} />;

  const showContentBasedOnUser = (loggedInContent: string, guestContent: string) => {
    return isLogedIn ? <> {loggedInContent}</> : <>{guestContent}</>;
  };

  const zaxDownloadText = appLogoDetails?.Title?.split(" ");

  const zaxDownloadFirstText = zaxDownloadText?.[0] ?? "DOWNLOAD";
  const zaxDownloadSecondText = zaxDownloadText?.slice(1)?.join(" ") ?? "THE ZAX APP";

  return (
    <HowRewardWorkStyled>
      <div className="howRewardsWork">
        <div className="howRewardsWorkTitleContainer">
          <Headings
            variant={headingVariantFirstWord}
            mobileFontSize={32}
            mobileLineHeight={32}
            mobileFontWeight={700}
            className="howRewardsWorkTitle"
          >
            How
          </Headings>
          <Headings variant={headingVariantSecondWord} fontWeight={700} mobileFontSize={28} className="howRewardsWorkTitle redPartOfHeader">
            rewards work
          </Headings>
        </div>
        <div className="columns">
          <div className="howRewardsWorkColumn">
            <Image src={REWARD_WORK_STEP_ONE_REBRAND} alt="How Reward Work Step One" width={106} height={204} />
            <Headings fontSize={28} fontWeight={800} variant="headingMedium2" className="topText">
              {showContentBasedOnUser("Earn Points", "Create")}
            </Headings>
            <Headings fontSize={28} fontWeight={800} lineHeight={28} variant="headingMedium2" className="bottomText">
              {showContentBasedOnUser("& track progress", "An Account & Sign In")}
            </Headings>
            <Paragraph variant="primaryParagraph1" className="description">
              {showContentBasedOnUser(
                "Turn every online order and in-person purchase into points.",
                "Sign up or log in to your account to start earning saucy rewards."
              )}
            </Paragraph>
          </div>
          <div className="howRewardsWorkColumn">
            <Image src={REWARD_WORK_STEP_TWO_REBRAND} alt="How Reward Work Step Two" width={106} height={204} />
            <Headings fontSize={28} fontWeight={800} variant="headingMedium2" className="topText">
              {showContentBasedOnUser("View Available", "Order")}
            </Headings>
            <Headings fontSize={28} fontWeight={800} lineHeight={28} variant="headingMedium2" className="bottomText">
              {showContentBasedOnUser("points & offers", "& earn points")}
            </Headings>
            <Paragraph variant="primaryParagraph1" className="description">
              {showContentBasedOnUser(
                "Check out the saucy rewards you can earn.",
                "Earn 10 points for every $1 you spend online or in-store."
              )}
            </Paragraph>
          </div>
          <div className="howRewardsWorkColumn">
            <Image src={REWARD_WORK_STEP_THREE_REBRAND} alt="How Reward Work Step Three" width={106} height={204} />
            <Headings fontSize={28} fontWeight={800} variant="headingMedium2" className="topText">
              {showContentBasedOnUser("SELECT REWARDS", "Redeem")}
            </Headings>
            <Headings fontSize={28} fontWeight={800} lineHeight={28} variant="headingMedium2" className="bottomText">
              {showContentBasedOnUser("IN SECONDS", "your rewards")}
            </Headings>
            <Paragraph variant="primaryParagraph1" className="description">
              {showContentBasedOnUser(
                "Redeem your points to enjoy flavor-full food.",
                "Add your favorite menu items to your cart, then choose a reward."
              )}
            </Paragraph>
          </div>
          <div className="howRewardsWorkColumn">
            <Image src={REWARD_WORK_STEP_FOUR_REBRAND} alt="How Reward Work Step Four" width={106} height={204} />
            <Headings fontSize={28} fontWeight={800} variant="headingMedium2" className="topText">
              {showContentBasedOnUser("ADD REWARDS", "Scan Receipt")}
            </Headings>
            <Headings fontSize={28} fontWeight={800} lineHeight={28} variant="headingMedium2" className="bottomText">
              {showContentBasedOnUser("TO ORDER", "& Start Earning")}
            </Headings>
            <Paragraph variant="primaryParagraph1" className="description">
              {showContentBasedOnUser(
                "Add your favorite menu items to your cart, then choose a reward.",
                "Add your receipt barcode number to earn points towards flavor-full food."
              )}
            </Paragraph>
          </div>
        </div>
        <div className="getStartedBtn">
          {isLogedIn && (
            <Button variant="primaryRed" onClick={navigateToMenuPage}>
              Start Your order
            </Button>
          )}
        </div>
        {!isDesktop && !isChallengesEnabled && (
          <div className="download-apps-section">
            <div className="download-apps-header">
              <Headings variant="primaryHeading1Mobile">{zaxDownloadFirstText}&nbsp;&nbsp;</Headings>
              <Headings variant="primaryHeading3" className="secondHeader">
                {zaxDownloadSecondText}
              </Headings>
            </div>
            {appLogos}
          </div>
        )}
      </div>
    </HowRewardWorkStyled>
  );
};

export default HowRewardWork;
